@use "src/theme";

.planIcon {
  position: absolute;
  bottom: 5rem;
  right: 1rem;

  path {
    fill: currentColor;
  }


  svg {
    height: 200px;
    max-height: 30vw;

    @media (max-width: 510px) {
      display: none;
    }
  }

}
